<template>
  <div
    class="toast"
    :class="[
      ...getVariantClasses,
      $data.show && !notificationWindowIsOpen ? 'show' : '',
    ]"
  >
    <div class="toast-inner">
      <Avatar
        class="invite-player-details-avatar"
        :url="getFriendAvatar.avatar"
        :crown="crownCondition(getFriendAvatar.crown, false)"
        size="60"
        classes="no-click-outside"
        @click.native="showUserDetails"
      />
      <div class="text" v-html="options.text"></div>
      <div class="buttons" v-if="getButtons.length > 0">
        <button
          v-for="(button, buttonIndex) in getButtons"
          :key="'toast-button-' + buttonIndex"
          :class="[...button.classes]"
          type="button"
          @click="joinGameFunc"
        >
          {{ button.text }}
        </button>
        <span v-if="enoughChips" class="toast-error">{{
          $t("playerActions.enoughChips")
        }}</span>
      </div>
      <div class="close-button flex-center" @click="show = false">
        <span class="sprite-table-pop-up exit-icon"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Helpers from "@/mixin/Helpers";
import StorageManager from "@/plugins/storage-manager";
import AppConstants from "@/constants/app";

const DEFAULT_DURATION = 10000;
const DEFAULT_OPTIONS = {
  text: "",
  variant: "default",
  duration: DEFAULT_DURATION,
  buttons: [],
};

export default {
  name: "FriendInviteToast",
  mixins: [Helpers],
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
  },
  computed: {
    ...mapState(["user", "jackpots", "scene"]),
    ...mapGetters(["getShortChipFormat"]),
    ...mapGetters("friends", [
      "lastInviteRequest",
      "getFriendCurrentGame",
      "getFriendsById",
    ]),
    ...mapGetters({
      texasRooms: `service/getTexasHoldemRooms`,
      omahaRooms: `service/getOmahaRooms`,
      getRealPlatform: "getRealPlatform",
      nAmISitting: "service/nAmISitting",
    }),
    notificationWindowIsOpen() {
      return (
        this.$store.state.modals.notificationWindow &&
        this.$store.state.modals.notificationWindowTabValue &&
        this.$store.state.modals.notificationWindowTabValue === "invitation"
      );
    },
    getVariantClasses() {
      const classes = [];
      if (this.$data.options?.variant)
        classes.push(`variant-${this.$data.options.variant}`);
      return classes;
    },
    getButtons() {
      if (typeof this.$data.options.buttons === "undefined") return [];
      return this.$data.options.buttons;
    },
    liveRoomAndGame() {
      if (this.inviteRequest && this.inviteRequest.userId) {
        const roomAndGame = this.getFriendCurrentGame(
          this.inviteRequest.userId
        );

        if (roomAndGame) {
          return roomAndGame;
        }
        return "";
      }
      return "";
    },
    getFriendAvatar() {
      if (this.inviteRequest && this.inviteRequest.userId) {
        const friend = this.getFriendsById(this.inviteRequest.userId);
        if (friend && friend.user) {
          return { avatar: friend.user.avatar, crown: friend.user.crown };
        }
      }
      return { avatar: "", crown: 0 };
    },
    getInviteRoom() {
      if (this.liveRoomAndGame && this.liveRoomAndGame.room) {
        if (this.$constant.gameType.BLACKJACK === this.liveRoomAndGame.game) {
          return this.liveRoomAndGame.room;
        } else if (
          this.$constant.gameType.TEXAS === this.liveRoomAndGame.game
        ) {
          return this.texasRooms.find(
            (room) => room.id == this.liveRoomAndGame.room
          );
        } else if (
          this.$constant.gameType.OMAHA === this.liveRoomAndGame.game
        ) {
          return this.omahaRooms.find(
            (room) => room.id == this.liveRoomAndGame.room
          );
        }
      }
      return false;
    },
  },
  watch: {
    lastInviteRequest: {
      handler(val) {
        if (val) {
          this.inviteRequest = val;
          this.onFriendInviteToastRequested({
            text: this.$t("toast.inviteRequestText", {
              name: val.name,
              game: this.requestGameNameText(this.gameTextComputed(val.game)),
            }),
            duration: 4000,
            variant: "invite",
            buttons: [
              {
                classes: ["ui-button ui-button-small-green"],
                text: this.$t("general.joinNow"),
              },
            ],
          });
        }
      },
      immediate: true,
    },
    show(val) {
      if (!val) {
        this.enoughChips = false;
      }
    },
    nAmISitting(val) {
      if (
        !val &&
        this.waitingEnterTable.status &&
        this.waitingEnterTable.tableId > -1
      ) {
        this.$store.dispatch("service/joinPokerRoom", {
          roomId: this.waitingEnterTable.tableId,
          directedGame: this.waitingEnterTable.directedGame,
        });
        this.waitingEnterTable = {
          status: false,
          tableId: -1,
          directedGame: "",
        };

        this.$store.commit(
          "friends/inviteListSplice",
          this.inviteRequest.userId
        );
      }
    },
  },
  data() {
    return {
      options: DEFAULT_OPTIONS,
      show: false,
      timeout: 0,
      innerTimeout: 0,
      slotInterval: null,
      inviteRequest: null,
      enoughChips: false,
      waitingEnterTable: {
        status: false,
        tableId: -1,
        directedGame: "",
      },
    };
  },
  methods: {
    joinGameFunc() {
      if (this.liveRoomAndGame) {
        if (
          (this.$constant.gameType.TEXAS === this.liveRoomAndGame.game ||
            this.$constant.gameType.OMAHA === this.liveRoomAndGame.game) &&
          this.getInviteRoom
        ) {
          if (
            this.user.chip < this.getInviteRoom.metadata.minBuy &&
            !this.user.vip.status
          ) {
            this.enoughChips = true;
            if (this.getRealPlatform !== "web" && this.scene === "Lobby") {
              this.$store.commit("modals/show", "shop");
            }
            this.show = false;
            this.$plugins.toast.show({
              text: this.$t("playerActions.enoughChips"),
              duration: 2500,
            });
            return;
          }
        }

        if (this.nAmISitting) {
          StorageManager.instance.remove({
            key: AppConstants.storageKeys.reconnect,
          });
          this.$store.state.service.colyseusConnection.ACTIVE_ROOM?.send(
            "leave"
          );
          this.waitingEnterTable = {
            status: true,
            tableId: this.liveRoomAndGame.room,
            directedGame: this.liveRoomAndGame.game,
          };
          this.show = false;
          return;
        }

        this.show = false;
        this.waitingEnterTable = {
          status: false,
          tableId: -1,
          directedGame: "",
        };

        let friendCurrentGameRoomInfo = this.getFriendCurrentGame(
          this.inviteRequest.userId
        );

        if (friendCurrentGameRoomInfo.room < 1) {
          this.$plugins.toast.show({
            text: this.$t("errors.userNotActiveGame"),
            duration: 2500,
          });
          this.show = false;
          return;
        }

        this.$store.commit("modals/hideModals", [
          "updateNotesWindow",
          "forceUpdateWindow",
        ]);

        switch (friendCurrentGameRoomInfo.game) {
          case this.$constant.gameType.TEXAS:
            this.$store.dispatch("service/joinPokerRoom", {
              roomId: friendCurrentGameRoomInfo.room,
              directedGame: friendCurrentGameRoomInfo.game,
            });
            break;
          case this.$constant.gameType.OMAHA:
            this.$store.dispatch("service/joinPokerRoom", {
              roomId: friendCurrentGameRoomInfo.room,
              directedGame: friendCurrentGameRoomInfo.game,
            });
            break;
          case this.$constant.gameType.BLACKJACK:
            this.$store.dispatch("service/joinToBlackjackRoom");
            break;
          case this.$constant.gameType.TURN_LEGENDS:
            this.$emit("slotPlay", "turnLegends");
            break;
          case this.$constant.gameType.BAMBOO_PANDA:
            this.$emit("slotPlay", "bambooPanda");
            break;
          case this.$constant.gameType.FAFAFA:
            this.$emit("slotPlay", "fafafa");
            break;
          case this.$constant.gameType.OLYMPUS:
            this.$emit("slotPlay", "olympus");
            break;

          default:
            console.log("Game not found !");
            break;
        }

        // setTimeout(() => {
        this.$store.commit(
          "friends/inviteListSplice",
          this.inviteRequest.userId
        );
        // }, 500);
      }
    },
    requestGameNameText(text) {
      return text === "Texas"
        ? "Texas Poker"
        : text === "Omaha"
        ? "Omaha Poker"
        : text;
    },
    onFriendInviteToastRequested(options) {
      this.$data.show = false;
      this.setDefaultOptions();

      this.$set(this.$data, "options", options);

      this.$data.show = true;

      if (this.$data.timeout) clearTimeout(this.$data.timeout);
      if (this.$data.innerTimeout) clearTimeout(this.$data.innerTimeout);

      this.$data.timeout = setTimeout(() => {
        this.$data.show = false;

        this.$data.innerTimeout = setTimeout(
          this.setDefaultOptions.bind(this),
          500
        );
      }, this.$data.options.duration ?? DEFAULT_DURATION);
    },
    setDefaultOptions() {
      this.$data.options = DEFAULT_OPTIONS;
    },
    showUserDetails() {
      this.$store.commit("modals/show", "userDetails");
    },
  },
};
</script>

<style lang="scss">
.toast {
  position: fixed;
  top: -1px;
  left: -1px;
  z-index: 91;
  width: 100%;
  min-height: 70px;
  max-height: 70px;
  transform-origin: top;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 1px;
  transform: scaleY(0);
  transition: all 0.15s ease-in-out;
  &.show {
    transform: scaleY(1);
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: inset 1px 3px 20px black;
    background: #86081e;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    // background: url("~@/assets/images/lobby/invite-toast-background.png")
    //     no-repeat 50% 50%,
    //   transparent;
    .text + .buttons {
      margin-left: 25px;
    }
    .text {
      font-size: 22px;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      // .ui-button {
      //   width: 80px;
      //   height: 25px;
      //   font-size: 18px;
      //   margin-right: 10px;
      //   text-shadow: 2px 2px 10px rgb(255, 255, 255);
      //   color: rgb(0, 0, 0);
      //   box-shadow: none !important;
      //   &::before {
      //     display: none;
      //   }
      //   &:last-child {
      //     margin-right: 0 !important;
      //   }
      //   &:active {
      //     transform: translateY(1px);
      //   }
      // }
      .ui-button {
        transform: scale(0.6);
      }
      .toast-error {
        font-size: 20px;
        color: rgb(255, 0, 0);
      }
    }
    .close-button {
      cursor: pointer;
      transform: scale(0.7);
      margin-left: 20px;
    }
    .invite-player-details-avatar {
      position: relative;
      right: 10px;
      top: 1px;
    }
    .close-button {
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 65px;
      border-radius: 100%;
      border: 3px solid #cf707f;
      cursor: pointer;
      transition: all 0.25s ease;
      &:active {
        filter: brightness(60%);
        -webkit-filter: brightness(60%);
      }
      .exit-icon {
        transform: scale(0.7);
      }
    }
  }
}
</style>
